import React, { Component } from 'react'

import { ReactFormGenerator, ElementStore } from 'react-form-builder2'
import { withRouter } from 'react-router'

var form_id, row_id, formStatus, jwt_token
const getUrl = process.env.REACT_APP_BASE_URL

class PreviewForm extends Component {
  _onSubmit(data) {}

  constructor(props) {
    super(props)
    this.state = {
      forms: null,
      error: null,
      isLoaded: false,
      items: null,
      itemsAnswer: null,
      fId: null,
      fTitle: '',
    }
  }

  componentDidMount() {
    const { id, pid, status, token } = this.props.match.params
    form_id = id
    row_id = pid
    formStatus = status
    jwt_token = token
    fetch(`${getUrl}forms?id=${id}`, {
      headers: { 'x-access-token': token },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            fId: id,
            fTitle: result.data[0].form_title,
          })
          if (result.data[0].form_details) {
            this.setState({
              items: result.data[0].form_details.task_data,
            })
          }
        },

        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        },
      )
    if (formStatus === 'preview') {
      fetch(`${getUrl}patient_answers?id=${row_id}`, {
        headers: { 'x-access-token': token },
      })
        .then((response) => response.json())
        .then(
          (result) => {
            if (result.data[0].answer) {
              this.setState({
                itemsAnswer: result.data[0].answer,
              })
            }
          },

          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          },
        )
    }
  }
  render() {
    return (
      <>
        {this.state.items === null && (
          <div>
            <b>Empty Form</b>
          </div>
        )}
        {this.state.items && this.state.itemsAnswer && formStatus === 'preview' && (
          <div>
            <h1>Title {this.state.fTitle} </h1>
            <ReactFormGenerator
              download_path=""
              answer_data={this.state.itemsAnswer}
              action_name="Save"
              form_method="POST"
              onSubmit={this._onSubmit}
              variables={this.props.variables}
              data={this.state.items}
              read_only={true}
              hide_actions={true}
            />
          </div>
        )}
      </>
    )
  }
}

export default withRouter(PreviewForm)
