import React, { Component } from 'react'
import { ReactFormGenerator, ReactFormBuilder, ElementStore } from 'react-form-builder2'
import { withRouter } from 'react-router'

import 'react-form-builder2/dist/app.css'
import { CCol, CRow } from '@coreui/react'

const getUrl = (cid) => `${process.env.REACT_APP_BASE_URL}forms?id=${cid}`
const mindClinicUrl = process.env.REACT_APP_Mind_CLINIC

var form_id, jwt_token

class FormBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      items: null,
      fId: null,
      fTitle: '',
      toast: false,
      toastMsg: null,
      toastColor: null,
      disableButton: false,
    }
  }

  componentDidMount() {
    this.onLoad()
  }
  onLoad = async () => {
    const { id, token } = this.props.match.params
    form_id = id
    jwt_token = token
    try {
      fetch(getUrl(form_id), {
        headers: { 'x-access-token': token },
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            isLoaded: true,
            fId: form_id,

            fTitle: result.data[0].form_title,
          })
          if (result.data[0].form_details) {
            this.setState({
              items: result.data[0].form_details.task_data
                ? result.data[0].form_details.task_data
                : result.data[0].form_details
                ? result.data[0].form_details
                : [],
            })
          } else {
            this.setState({
              items: [],
            })
          }
        })
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      })
    }
  }
  onPost = (data) => {
    this.setState({
      items: data.task_data,
    })
  }

  onSubmitData = async () => {
    this.setState({
      disableButton: true,
    })
    const form_details = JSON.stringify({ task_data: this.state.items })
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt_token },
      body: JSON.stringify({ form_details }),
    }
    const res = await fetch(getUrl(form_id), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            toast: true,
            toastMsg: 'Form submitted successfully!',
            disableButton: false,
            toastColor: 'green',
          })
          setTimeout(() => {
            window.close()
          }, 2000)
        } else {
          this.setState({
            toast: true,
            toastMsg: 'There is problem!',
            disableButton: false,
            toastColor: 'red',
          })
        }
      })
  }

  render() {
    return (
      <>
        {this.state.items && (
          <div>
            <h1>Title {this.state.fTitle} </h1>
            {/* <a href={`${mindClinicUrl}#/formTitle`} target="_blank"> */}
            <CRow>
              <CCol sm="auto">
                <button
                  onClick={this.onSubmitData}
                  className="btn btn-primary"
                  disabled={this.state.disableButton}
                >
                  Submit From
                </button>
              </CCol>
              <CCol sm="auto">
                {this.state.toast && (
                  <div className="mx-3" style={{ alignSelf: 'center' }}>
                    <p style={{ color: this.state.toastColor, fontWeight: 'bold' }}>
                      {this.state.toastMsg}
                    </p>
                  </div>
                )}
              </CCol>
            </CRow>

            {/* </a> */}
            <br />
            <br />
            <ReactFormBuilder data={this.state.items} onPost={this.onPost} />
          </div>
        )}
      </>
    )
  }
}

export default withRouter(FormBuilder)
