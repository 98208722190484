import fetch from 'isomorphic-fetch';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  OPTIONS: '',
};

export function post(url, data) {
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then(response => response);
}

export async  function put(url, data) {
  let response = await fetch(url, {
    method: 'PUT',
    headers,
    body: data,
  });
  if (response.status == 200) {
    let json = await response.json(); // (3)
    return json;
  }

  throw new Error(response.status);

}

export async  function get(url, data) {
  let response = await fetch(url, {
    method: 'GET',
    headers,
  });
  if (response.status == 200) {
    let json = await response.json(); // (3)
    return json;
  }

  throw new Error(response.status);

}

/* export function get(url) {
  return fetch(url, {
    method: 'GET',
    headers,
  }).then(response => response.json());
} */
