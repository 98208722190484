import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { ReactFormGenerator, ReactFormBuilder, ElementStore } from 'react-form-builder2'

import { get, post } from './requests'
import * as variables from './variables'
import DemoBar from './demobar'
import PreviewForm from './preview'
import FormBuilder from './FormBuilder'
import ReadOnlyPreview from './ReadOnlyPreview'

const getUrl = (cid) => `${process.env.REACT_APP_BASE_URL}forms?cid=${cid}`

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { formId: '11', data: [], previewVisible: false }
    this.formId = this.state.formId
    this.handleChange = this.handleChange.bind(this)
    this._onUpdate = this._onChange.bind(this)
    this.searchParam = this.state.useSearchParams
  }
  componentDidMount() {
    ElementStore.subscribe((state) => this._onUpdate(state.data))
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    })
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    })
  }

  _onChange(data) {
    this.setState({
      data,
    })
  }

  _onSubmit(data) {
    // Place code to post json data to server here
  }

  formId

  handleChange(event) {
    this.formId = event.target.value
    const url = getUrl(this.formId)
    ElementStore.dispatch('load', { loadUrl: url })
    this.setState({ formId: this.formId })
  }

  onLoad = () => {
    const url = getUrl(this.formId)
    return get(url)
  }

  onPost = (data) => {
    const saveUrl = getUrl(this.formId)
    post(saveUrl, data)
  }

  render() {
    return (
      <div className="App">
        <Router>
          <div>
            {/* <ul>
              <li>
                <Link to="/">Main</Link>
              </li>
              <li>
                <Link to={`/preview/10/-1/nothing`}>preview</Link>
              </li>
              <li>
                <Link to={`/formBuilder/${this.formId}`}>Form Builder</Link>
              </li>
              <li>
                <Link to={`/readOnly/${10}/${4}/${'preview'}`}>Read only Preview</Link>
              </li>
            </ul> */}

            {/* <hr /> */}

            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            <Switch>
              <Route exact path="/preview/:id/:pid/:status/:token">
                <PreviewForm />
              </Route>
              <Route exact path="/readOnly/:id/:pid/:status/:token">
                <ReadOnlyPreview />
              </Route>
              <Route exact path="/formBuilder/:id/:token">
                <FormBuilder />
              </Route>
              <Route exact path="/:id/:token">
                <Main
                  formId={this.state.formId}
                  onChange={this.handleChange}
                  onLoad={this.onLoad}
                  onPost={this.onPost}
                />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}

function Main(props) {
  return (
    <div className="App">
      <label>Select your form:</label>
      <select className="form-control" value={props.formId} onChange={props.handleChange}>
        <option value="1">Form 1</option>
        <option value="2">Form 2</option>
      </select>
      {/*  <Main formId={this.state.formId} onChange= {this.handleChange} onLoad = {this.onLoad} onPost = {this.onPost}/> */}
      <hr></hr>
      <button>save</button>
      <ReactFormBuilder
        // data={this.state.data}
        onLoad={props.onLoad}
        onPost={props.onPost}
      />
    </div>
  )
}

export default App
